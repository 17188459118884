.shadowButtonActivateBot {
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
}

.buttonActivateBot {
    display: block;
    padding: 5px 15px;
    border-radius: 12px;
    font-size: 1rem;
    color: white;
    transform: translateY(-6px);
}

.shadowButtonActivateBot:active .buttonActivateBot {
    transform: translateY(-2px);
}
/*
.buttonActivateBot-active-color {
    background: #18A300;
}
.shadowButtonActivateBot-active-color {
    background: #12C902;
} */

.shadowButtonActivateBot-active-color {
    background: #0269A0;
}
.buttonActivateBot-active-color {
    background: #038fde;
}


.shadowButtonActivateBot-deactive-color {
    background: #A30036;
}
.buttonActivateBot-deactive-color {
    background: #EF003B;
}

.shadowButtonActivateBot-disabled-color {
    background: #6B6B6B;
}
.buttonActivateBot-disabled-color {
    background: #999999;
}




