.box-master {
  width: 100%;
  padding: 8px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  border-radius: 10px;
}

.box-level-2 {
  padding-left: 20px;
  margin: 10px 0;
}

.box-level-1 {
  width: 100%;
}
