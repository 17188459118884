.contenedorPendientes{
    padding: 10px;
    border: 1px solid rgb(202,202,202);
    border-radius: 10px;
    margin-bottom: 15px;
}
.contenedorAlert{
    margin-bottom: 20px !important;
}
.centrarBtn{
    margin-top: 10px !important;
    display: flex;
    justify-content: center;
    align-content: center;
}